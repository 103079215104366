import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { UserManager } from "oidc-client";
import authConfig from "../../../authConfig";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const HANGFIRE_URL = API_BASE_URL + 'hangfire';
async function getAccessToken() {
    const userManager = new UserManager({
        ...authConfig,
        client_id: authConfig.clientId,
        automaticSilentRenew: true,
    });
    const user = await userManager.getUser();

    return user?.access_token;
}

const useStyles = makeStyles(() => ({
    frame: {
        width: "100vw",
        height: "75vh",
        maxWidth: "100%",
        border:"none"
    },
}));

export const HangfireDashboard = () => {

    const [iframeSource, setIframeSource] = useState('');
    const classes = useStyles();
    
    getAccessToken()
        .then((accessToken) => {
            setIframeSource(HANGFIRE_URL + '?jwt_token=' + accessToken);
        }, (errorReason) => { setIframeSource('') });

    let dashboardHtml;
    if (iframeSource !== '') {
        dashboardHtml =
        <iframe
            title="Hangfire"
            src={iframeSource}
            className={classes.frame}
            loading="lazy"
        >
        </iframe>
    } else {
        dashboardHtml = <React.Fragment>Loading ...</React.Fragment>
    }

    return (
        <React.Fragment>
        <h2>Hangfire Dashboard</h2>
            {dashboardHtml}
        </React.Fragment>
    );
};