import { PermissionApiItem } from "../../api/permission";

export const REQUEST_DATA = "permissions.REQUEST_DATA";
export const SET_DATA = "permissions.SET_DATA";

export const Permissions = {
  CanAccessCommissions: "CanAccessCommissions",
  AssetsMenu: "AssetsMenu",
  ReadAssets: "ReadAssets",
  WriteAssets: "WriteAssets",
  ReceivableMenu: "ReceivableMenu",
  PayableMenu: "PayableMenu",
  ProductsMenu: "ProductsMenu",
  HangfireDashboard: "HangfireDashboard",
};

export type PermissionsState = {
  items: PermissionApiItem[] | null;
  fetching: boolean;
};

interface RequestDataAction {
  type: typeof REQUEST_DATA;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    permissions: PermissionApiItem[];
  };
}

export type PermissionsActionTypes = SetDataAction | RequestDataAction;
