import { requestFile } from "../..";
import { DateString } from "../../../shared/helpers/dates";

export type OSCommissionsForCOSAccuralsReportParams = {
  startDate: DateString | null;
    endDate: DateString | null;
    postedDate: String | null;
};

export type OSCommissionsForCOSAccuralsParams = {
    startDate?: DateString;
    endDate?: DateString;
};

export function getOSCommissionsForCOSAccuralsReport(params: OSCommissionsForCOSAccuralsReportParams) {
    return requestFile("account-report/generate-cos-accrual-report", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
