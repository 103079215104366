import FileDownload from "js-file-download";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useQueryParams } from "use-query-params";
import { OSCommissionsForCOSAccuralsParams } from "../../../api/account-report/generate-os-commissions-for-cos-accurals-report";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { dateRangeParser} from "../../../shared/helpers/queryParsing";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { getOSCommissionsForCOSAccuralsReport } from "../../../api/account-report/generate-os-commissions-for-cos-accurals-report";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { RequestError } from "../../../api/types";
import { DatePicker } from "@mui/x-date-pickers";
import { toDateString } from "../../../shared/helpers/dates";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    menuItem: {
        marginTop: "30px"
    },
}));

export const OSCommissionsForCOSAccrualsReport = () => {
    const [filters, updateFilters] = useQueryParams({
        date: dateRangeParser
  });

    const classes = useStyles();

  const [postedDate, setPostedDate] = useState<Date | null>(null);
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<RequestError | undefined>(undefined);

    const disabled =
        filters.date.some((d) => d === null) || postedDate == null;

  const filterMeta = [
    {
      name: "date",
      label: "Date Range",
      isDateRange: true,
      },
      {
          name: "postedDate",
          label: "Posted Date",
          isDateRange: true,
      }
  ];

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
        )
      );
      setPostedDate(null);
  };

  const handleExport = () => {
    setIsExporting(true);
      setError(undefined);

    const startDate = filters.date[0];
      const endDate = filters.date[1];
      const postedDateString = postedDate ? toDateString(postedDate.toISOString()) : '';

      getOSCommissionsForCOSAccuralsReport({
      startDate,
          endDate,
          postedDate: postedDateString,
    })
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .catch((error: RequestError) => {
        setError(error);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Outstanding Commission for COS Accrual Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
            disabled={disabled}
          >
            Export
          </LoaderButton>
        </Box>
      </Box>
      {error && (
        <Box display="flex" justifyContent="space-between">
          <CustomStatusChip
            type="error"
            title={error.type}
            message={error.message}
          />
        </Box>
      )}
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={undefined}
        controls={() => (
          <React.Fragment>
            <DateRangeControl
              name="Date Range"
              id="start-date-range-filter-control"
                    selected={filters.date}
              onChange={(date) => updateFilters({ date })}
                />
                <DatePicker
                    slotProps={{
                        textField: { size: 'small' }
                    }}
                    className={classes.menuItem}
                format="dd/MM/yyyy"
                label="Posted Date"
                value={postedDate || null}
                onChange={(val) => {
                    setPostedDate(val);
                }}
                />
            </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as OSCommissionsForCOSAccuralsParams}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
    </React.Fragment>
  );
};